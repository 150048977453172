import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getQueueLog } from "../../store/QueuesStore/queues"
import ModalFS from "../../components/Modal/fullScreen"
import QueueDetails from "./QueueDetails"

const ServiceQueue = () => {
    const {service} = useParams()
    const dispatch = useDispatch()
    const log = useSelector((state) => state.queues.log)

    useEffect(() => {
        dispatch(getQueueLog(service))
    }, [dispatch, service])

    console.log(log)

    if (log === null) {
        return (
            <>LOAD...</>
        )
    }

    return (
        <>
            <h1>Очередь {log.service.name}</h1>
            <h2>Ошибки</h2>
            <Log data={log.errors} service={service} />
            <h2>Лог</h2>
            <Log data={log.log} service={service} />
        </>
    )
}

export default ServiceQueue

const Log = ({data, service}) => {

    const [d, setD] = useState(false)
    const [r, setR] = useState(null)

    const rowHandler = (row) => {
        setR(row)
        setD(true)
    }

    const closeHandler = () => {
        setD(false)
        setR(null)
    }

    if(!data || data.length === 0) {
        return(
           <div>Нет логов</div>
       )
    }

    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Время</th>
                        <th>Статус</th>
                        <th>Компания</th>
                        <th>Маркетплейс</th>
                        <th>Тип</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((e, i) => <LogRow key={i} data={e} handler={rowHandler} />)}
                </tbody>
            </table>
            {d ? <ModalFS body={<QueueDetails log={r} service={service} />} close={closeHandler} /> : null}
        </div>
    )
}

const LogRow = ({data, handler}) => {
    return (
        <tr onClick={() => handler(data)}>
            <td>{data.updated}</td>
            <td>{data.status}</td>
            <td>{data.company_id}</td>
            <td>{data.shop_code ?? "-"}</td>
            <td>{data.work ?? "-"}</td>
        </tr>
    )
}