import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'

export const Logger = createSlice({
    name: 'Logger',
    initialState: {
        logs: [],
        line: [],
        filters: {
            count: 20,
            service: "all",
            viewed: true,
        }
    },
    reducers: {
        setLogs: (state, action) => {
            state.logs = action.payload
        },
        setLine: (state, action) => {
            state.line = action.payload
        },
        setFilters: (state, action) => {
            state.filters = action.payload
        }
    }
})

export const { setLogs, setLine, setFilters } = Logger.actions

export const getLoggerLogs = (type) => (dispatch, getState) => {
    let f = getState().logger.filters
    let config = {
        method: 'get',
        url: `${gatewayURL}/logs/${type}?s=${f.service}&c=${f.count}&v=${f.viewed}`,
        headers: { 
            'Content-Type': 'application/json',
        }
    };

    axios.request(config)
    .then((response) => {
        dispatch(setLogs(response.data.result))
    })
    .catch((error) => {
        console.log(error)
    });
}

export const getLoggerLine = (reqID) => dispatch => {
    let config = {
        method: 'get',
        url: `${gatewayURL}/logs/details/${reqID}`,
        headers: { 
            'Content-Type': 'application/json',
        }
    };

    axios.request(config)
    .then((response) => {
        dispatch(setLine(response.data.result))
    })
    .catch((error) => {
        console.log(error)
    });
}

export const setLogViewed = (reqID, view) => dispatch => {
    let config = {
        method: 'put',
        url: `${gatewayURL}/logs/details/${reqID}`,
        headers: { 
            'Content-Type': 'application/json',
        },
        data: {
            viewed: view
        }
    };

    axios.request(config)
    .then((response) => {
//        dispatch(setLine(response.data.result))
        console.log(response.data)
    })
    .catch((error) => {
        console.log(error)
    });
}



export default Logger.reducer
