import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLoggerLine, setLogViewed } from "../../store/LogStore/logger"

const LogDetails = ({log}) => {

    const dispatch = useDispatch()

    const handlerView = (view) => {
        dispatch(setLogViewed(log.ReqID, view))
    }

    return (
        <div>
            <table className="table-details">
                <tbody>
                    <tr>
                        <td>Время записи</td>
                        <td>{log.Time}</td>
                    </tr>
                    <tr>
                        <td>Начало</td>
                        <td>{log.TimeData.Start}</td>
                    </tr>
                    <tr>
                        <td>Конец</td>
                        <td>{log.TimeData.End}</td>
                    </tr>
                    <tr>
                        <td>Длительность</td>
                        <td>{log.TimeData.Time_ms / 1000000}</td>
                    </tr>
                    <tr>
                        <td>Сервис</td>
                        <td>{log.Service}</td>
                    </tr>
                    <tr>
                        <td>Уровень</td>
                        <td>{log.Level}</td>
                    </tr>
                    <tr>
                        <td>Тип</td>
                        <td>{log.Msg}</td>
                    </tr>
                    <tr>
                        <td>Лог</td>
                        <td>{log.Message}</td>
                    </tr>
                    <tr>
                        <td>Источник</td>
                        <td>{log.Resource}</td>
                    </tr>
                    <tr>
                        <td>ReqID</td>
                        <td>{log.ReqID}</td>
                    </tr>
                    <tr>
                        <td>JobID</td>
                        <td>{log.JobID}</td>
                    </tr>
                    <tr>
                        <td>Пользователь</td>
                        <td>{log.Auth.UserID}</td>
                    </tr>
                    <tr>
                        <td>Компания</td>
                        <td>{log.Auth.CompanyID}</td>
                    </tr>
                    <tr>
                        <td>Host</td>
                        <td>{log.RequestData.Host}</td>
                    </tr>
                    <tr>
                        <td>Method</td>
                        <td>{log.RequestData.Method}</td>
                    </tr>
                    <tr>
                        <td>RemoteAddr</td>
                        <td>{log.RequestData.RemoteAddr}</td>
                    </tr>
                    <tr>
                        <td>RequestURI</td>
                        <td>{log.RequestData.RequestURI}</td>
                    </tr>
                </tbody>
            </table>

            {!log.Close ?
            <div style={{width: 200, margin: '20px 0px'}}>
                <button onClick={() => handlerView(true)}>Проверено</button>
            </div> :
            <div style={{width: 200, margin: '20px 0px'}}>
                <button onClick={() => handlerView(false)}>Отменить проверено</button>
            </div> }

            <LogLine reqID={log.ReqID} />
        </div>
    )
}

export default LogDetails

const LogLine = ({reqID}) => {

    const dispatch = useDispatch()
    const line = useSelector((state) => state.logger.line)

    useEffect(() => {
        if (reqID) {
            dispatch(getLoggerLine(reqID))
        }
    }, [reqID, dispatch])

    if (!reqID) {
        return null
    }

    if (line.length === 0) {
        return (
            <div>Preload....</div>
        )
    }

    return (
        <div className="line" style={{height: line.length * 30}}>
            {line.map((e, i) => <Span key={i} span={e} index={i} firstStart={line[0].TimeData.Start} />)}
        </div>
    )
}

const Span = ({span, index, firstStart}) => {
    return (
        <div className="span-line" 
                style={{
                    top: index * 30, 
                    width: span.TimeData.Time_ms / 1000,
                    backgroundColor: span.Level === "ERROR" ? "red" : "green", 
                    left: (span.TimeData.Start - firstStart) / 1000 }}>
            <div className="span-line-label">{span.Resource} {'->'} {span.Service} {span.Message} {span.TimeData.Time_ms / 1000000}</div>
        </div>
    )
}