import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLoggerLogs, setFilters } from "../../store/LogStore/logger"
import ModalFS from "../../components/Modal/fullScreen"
import LogDetails from "./LogDetails"
import { getServicesList } from "../../store/ServicesStore/services"

const loggerMenu = [
    {
        "label": "Все логи",
        "type": "logs"
    },
    {
        "label": "Ошибки",
        "type": "errors"
    },
    {
        "label": "Длинные",
        "type": "long"
    }
]

const LoggerPage = () => {

    const dispatch = useDispatch()
    const [type, setType] = useState(loggerMenu[0].type)
    const logs = useSelector((state) => state.logger.logs)
    const filters = useSelector((state) => state.logger.filters)

    useEffect(() => {
        dispatch(getLoggerLogs(type))
    }, [dispatch, type, filters])

    const menuHandler = (t) => {
        setType(t)
    }

    return (
        <div>
            <h1>Логи</h1>
            <LoggerMenu active={type} handler={menuHandler} />
            <LoggerFilter />
            <LogList data={logs} />
        </div>
    )
}

export default LoggerPage

const LoggerMenu = ({active, handler}) => {
    return (
        <ul className="line-menu">
            {loggerMenu.map((e, i) => <li key={i} 
                                            onClick={() => handler(e.type)}
                                            className={e.type === active ? "active" : ""}>{e.label}</li>)}
        </ul>
    )
}

const LoggerFilter = () => {

    const dispatch = useDispatch()
    const filters = useSelector((state) => state.logger.filters)
    const services = useSelector((state) => state.services.services)

    const [fCount, setFCount] = useState(filters.count)
    const [fService, setFService]  = useState(filters.service)
    const [fViewed, setFViewed]  = useState(filters.viewed)

    useEffect(() => {
        if (services.length === 0) {
            dispatch(getServicesList())
        }
    }, [services, dispatch])


    const setF = () => {
        dispatch(setFilters(
            {
                count: parseInt(fCount),
                service: fService,
                viewed: fViewed,
            }
        ))
    }

    return (
        <div className="line-filters">
            <div className="mr-20">
                <select onChange={(e) => setFService(e.target.value)} className="select">
                    <option value="all">Все сервисы</option>
                    {services.map((e, i) => <option key={i} value={e.code}>{e.name}</option>)}
                </select>
            </div>
            <div className="mr-20">
                <label className="mr-10" htmlFor="countLogs">Количество</label>
                <input id="countLogs" type="number" style={{width: 100}} value={fCount} onChange={e => setFCount(e.target.value)} />
            </div>
            <div className="mr-20">
                <input checked={fViewed} id="countLogsNew" type="checkbox" className="mr-10" onChange={() => setFViewed(!fViewed)} />
                <label htmlFor="countLogsNew">Показать просмотренные</label>
            </div>
            <div className="mr-20">
                <button onClick={setF}>Применить</button>
            </div>
        </div>
    )
}

const LogList = ({data}) => {
    const [d, setD] = useState(false)
    const [r, setR] = useState(null)

    const rowHandler = (row) => {
        setR(row)
        setD(true)
    }

    const closeHandler = () => {
        setD(false)
        setR(null)
    }

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th>Время лога</th>
                        <th>Тип</th>
                        <th>Сервис</th>
                        <th>Функция</th>
                        <th>Источник</th>
                        <th>Длительность</th>
                        <th>Просмотрено</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((e, i) => <LogRow key={i} data={e} handler={rowHandler} />)}
                </tbody>
            </table>

            {d ? <ModalFS body={<LogDetails log={r} />} close={closeHandler} /> : null}
        </>
    )
}

const LogRow = ({data, handler}) => {
    return (
        <tr onClick={() => handler(data)}>
            <td>{data.Time}</td>
            <td>{data.Level}</td>
            <td>{data.Service}</td>
            <td>{data.Message}</td>
            <td>{data.Resource}</td>
            <td>{data.TimeData.Time_ms / 1000000}</td>
            <td><div className={data.Close ? "dot yes" : "dot no"}></div></td>
        </tr>
    )
}