import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getQueueLine, resetQueueError } from "../../store/QueuesStore/queues"

const QueueDetails = ({log, service}) => {

    const dispatch = useDispatch()

    const line = useSelector((state) => state.queues.line)

    useEffect(() => {
        dispatch(getQueueLine(log.id, service))
    }, [dispatch, log, service])

    console.log(line)

    return (
        <div>
            <h2>Информация</h2>
            <table className="table-details">
                <tbody>
                    <tr>
                        <td>ID</td>
                        <td>{log.id}</td>
                    </tr>
                    <tr>
                        <td>Компания</td>
                        <td>{log.company_id}</td>
                    </tr>
                    <tr>
                        <td>Статус</td>
                        <td>{log.status}</td>
                    </tr>
                    <tr>
                        <td>Создана</td>
                        <td>{log.created}</td>
                    </tr>
                    <tr>
                        <td>Последнее обновление</td>
                        <td>{log.updated}</td>
                    </tr>
                </tbody>
            </table>
            <div style={{width: 200, margin: '20px 0px'}}>
                <button onClick={() => dispatch(resetQueueError(service, log.id))}>Сбросить ошибку</button>
            </div>

            <h2>Связанные логи</h2>
        </div>
    )
}

export default QueueDetails